import type { FC } from 'react';
import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import { ExclamationCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import type { UserExistCheckType } from '@wilm/shared-backend/commerce-commercetools/interfaces/AccountExtended';
import { LoginStates } from '@wilm/shared-backend/commerce-commercetools/interfaces/AccountExtended';
import type { Account } from '@wilm/shared-types/account';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import PasswordInput from 'components/commercetools-ui/atoms/input-password';
import Link from 'components/commercetools-ui/atoms/link';
import Modal from 'components/commercetools-ui/atoms/modal';
import type { ReCaptchaRef } from 'components/commercetools-ui/atoms/reCaptcha';
import ReCaptcha from 'components/commercetools-ui/atoms/reCaptcha';
import Typography from 'components/commercetools-ui/atoms/typography';
import ActivationModal from 'components/commercetools-ui/organisms/authentication/activation-modal';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import useValidate from 'helpers/hooks/useValidate';
import scrollToError from 'helpers/utils/scrollToError';
import { useLoginInfoSettingsContext } from 'providers/login-info';
import { useAccount } from 'frontastic';
import type { LoginErrors } from 'frontastic/hooks/useAccount/types';
import Feedback from '../../account/account-atoms/feedback';

interface Props {
    onLogin?: (account: Account) => void;
    registrationRedirectTextContent: string;
    registrationRedirectButtonText: string;
    registrationNoteContent: string;
}

interface PageData {
    heading: string;
    buttonText: string;
    submit: () => Promise<void> | (() => void);
}

enum SignPageState {
    INITIAL = 'initial',
    LOGIN = 'login',
    FORGOT_PASSWORD = 'forgot-password'
}

const LoginForm: FC<Props> = ({ onLogin, registrationRedirectTextContent, registrationRedirectButtonText, registrationNoteContent }) => {
    //i18n messages
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const { formatMessage: formatValidationMessage } = useFormat({ name: 'validation' });
    const { formatMessage } = useFormat({ name: 'common' });
    const [accountData, setAccountData] = useState<UserExistCheckType['accountData'] | null>(null);

    const router = useRouter();

    const { pathWithoutQuery } = usePath();

    const isOnCartPage = pathWithoutQuery === '/';

    const { validateEmail, validateTextExists } = useValidate();

    const [reCaptchaReady, setReCaptchaReady] = useState(false);

    //Contexts Providers
    const loginInfoSettings = useLoginInfoSettingsContext();

    //account actions
    const { checkUserExist, login, requestPasswordReset } = useAccount();

    //login data
    const [data, setData] = useState({ email: '', password: '' });

    //error
    const [error, setError] = useState({} as LoginErrors);

    //success
    const [success, setSuccess] = useState('');

    //processing...
    const [loading, setLoading] = useState(false);

    const reCaptchaRef = useRef<ReCaptchaRef>(null);

    const [signPageState, setSignPageState] = useState(SignPageState.INITIAL);

    const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
    const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);

    const resetFeedback = () => {
        setError({});
        setSuccess('');
    };

    //get back to initial login modal
    const backToInitial = () => {
        setSignPageState(SignPageState.INITIAL);

        resetFeedback();
    };

    //get back to login modal
    const goToLogin = () => {
        setSignPageState(SignPageState.LOGIN);

        resetFeedback();
    };

    //requesting a password reset
    const toResendPassword = () => {
        setSignPageState(SignPageState.FORGOT_PASSWORD);

        resetFeedback();
    };

    const [value, setValue] = useState({ email: '', password: '' });
    const initialLoginFormErrors = { email: '', password: '' };
    const [loginFormErrors, setLoginFormErrors] = useState(initialLoginFormErrors);

    //handle checkbox input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
        e.target.name === 'email' ? setValue({ ...value, email: e.target.value }) : setValue({ ...value, password: e.target.value });
    };

    const closeRedirectModal = useCallback(() => {
        setIsRedirectModalOpen(false);
        router.replace(`/register/?existing=${encodeURIComponent(data.email)}&${isOnCartPage ? 'lvp=?' : ''}`);
    }, [data, isOnCartPage]);

    //find user
    const handleUserExistCheck = async () => {
        setLoading(true);
        const token = await reCaptchaRef.current?.executeAsync();

        const response = await checkUserExist(data.email, token);

        if (!('source' in response)) {
            setError(response);
            setLoading(false);
            return;
        }

        const { source, accountData } = response;

        switch (source) {
            case LoginStates.ACTIVATION_MODAL:
                setSignPageState(SignPageState.INITIAL);
                setAccountData(accountData);
                setIsActivationModalOpen(true);
                break;
            case LoginStates.SHOW_PASSWORD:
                setAccountData(accountData);
                setSignPageState(SignPageState.LOGIN);
                break;

            // todo how to transfer account data here?!?!
            case LoginStates.TRIMMED:
                router.push(`/register/?existing=${encodeURIComponent(data.email)}&${isOnCartPage ? 'lvp=?' : ''}`);
                break;
            default:
                router.push(`/register/?email=${encodeURIComponent(data.email)}&${isOnCartPage ? 'lvp=?' : ''}`);
                break;
        }

        setLoading(false);
    };

    //login user
    const loginUser = async () => {
        setLoading(true);

        try {
            const token = await reCaptchaRef.current?.executeAsync();
            const response = (await login(data.email, data.password, true, token)) as Account;

            if (response.isExisting) {
                setIsRedirectModalOpen(true);
            }

            if (response.accountId) {
                onLogin?.(response);
                track({ type: TagsActionType.LOGIN, payload: { method: 'default' } });
            } else {
                const errorResponse = response as LoginErrors;
                setError(errorResponse);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError({
                loginError: {
                    message: formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' })
                }
            });
        }
    };

    //request a password reset for user
    const resendPasswordResetForUser = useCallback(async () => {
        try {
            const requestReset = await requestPasswordReset(data.email);
            if (requestReset.error) {
                setError({
                    loginError: {
                        message: formatErrorMessage({ id: requestReset.error, defaultMessage: 'Sorry. Something went wrong..' })
                    }
                });
                setSuccess('');
            } else {
                setSuccess(
                    formatAccountMessage({
                        id: 'verification.resent',
                        defaultMessage: 'An email was sent to {email}',
                        values: { email: data.email }
                    })
                );
                setError({});
            }
        } catch (err) {
            setError({
                loginError: {
                    message: formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' })
                }
            });
        }
    }, [data]);

    const validateLoginFields = useCallback(() => {
        const newLoginFormErrors = { ...initialLoginFormErrors };

        // Validate email
        if (!validateTextExists(data.email)) {
            newLoginFormErrors.email = 'error.missing.email';
        } else if (!validateEmail(data.email)) {
            newLoginFormErrors.email = 'error.validation.email';
        }

        if (!validateTextExists(data.password) && signPageState === SignPageState.LOGIN) {
            // Validate password
            newLoginFormErrors.password = 'error.missing.password';
        }

        setLoginFormErrors(newLoginFormErrors);
        setError({});
        setSuccess('');

        return JSON.stringify(newLoginFormErrors) === JSON.stringify(initialLoginFormErrors);
    }, [data, signPageState]);

    const pageData: PageData = useMemo(() => {
        switch (signPageState) {
            case SignPageState.INITIAL:
                return {
                    heading: formatAccountMessage({ id: 'welcome', defaultMessage: 'Welcome' }),
                    buttonText: formatAccountMessage({ id: 'continue', defaultMessage: 'Continue' }),
                    submit: handleUserExistCheck
                };
            case SignPageState.FORGOT_PASSWORD:
                return {
                    heading: formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' }),
                    buttonText: formatAccountMessage({ id: 'account.reset.link', defaultMessage: 'Get reset link' }),
                    submit: resendPasswordResetForUser
                };
            default:
                return {
                    heading: formatAccountMessage({ id: 'welcome.back', defaultMessage: 'Welcome back' }),
                    buttonText: formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' }),
                    submit: loginUser
                };
        }
    }, [signPageState, handleUserExistCheck, loginUser, goToLogin, resendPasswordResetForUser]);

    //form submission
    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const isLoginFieldsValid = validateLoginFields();

            if (isLoginFieldsValid) {
                await pageData.submit();
            } else {
                scrollToError();
            }
        },
        [validateLoginFields, pageData]
    );

    useEffect(() => {
        if (loginFormErrors) {
            scrollToError();
        }
    }, [loginFormErrors]);

    const formatCompanyInfoMessage = useMemo(() => {
        const tradingName = accountData?.tradingName ?? '';

        return loginInfoSettings.loginCompanyInfo.replaceAll('{accountName}', tradingName).replaceAll('{emailAddress}', data.email);
    }, [loginInfoSettings.loginCompanyInfo, data.email, accountData?.tradingName]);

    const formatCompanyAdditionalInfoMessage = useMemo(() => {
        const tradingName = accountData?.tradingName ?? '';

        return loginInfoSettings.loginCompanyAdditionalInfo.replaceAll('{accountName}', tradingName);
    }, [loginInfoSettings.loginCompanyAdditionalInfo, accountData?.tradingName]);

    return (
        <>
            {registrationNoteContent && (
                <div className="m-auto mb-20 grid max-w-[530px] border-b border-checkout-bg pb-20 ">
                    <div className="flex items-start">
                        <ExclamationCircleIcon className="mr-10 w-30 min-w-30 stroke-register-note" />
                        <Markdown markdown={registrationNoteContent} />
                    </div>
                </div>
            )}

            {signPageState === SignPageState.LOGIN && accountData?.type === 'B2B' && (
                <>
                    {loginInfoSettings.loginCompanyInfoEnabled && (
                        <div className="m-auto grid max-w-[530px] pb-20 ">
                            <Markdown className="flex items-start" markdown={formatCompanyInfoMessage} />
                        </div>
                    )}
                </>
            )}

            <Typography as="h3" className="mb-16 text-18 md:text-20">
                {pageData.heading}
            </Typography>

            <form data-cy="login-form" onSubmit={handleSubmit} noValidate={true}>
                <Feedback success={success} error={error.loginError?.message ?? ''} />
                <div className="mb-40 grid gap-12">
                    <Input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={value.email}
                        label={formatMessage({
                            id: 'emailAddress',
                            defaultMessage: 'Email Address'
                        })}
                        placeholder={formatMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}
                        onChange={handleChange}
                        hideCheckIcon
                        data-cy="email"
                        errorMessage={
                            loginFormErrors?.email
                                ? formatValidationMessage({
                                      id: loginFormErrors.email,
                                      defaultMessage: 'Enter your email'
                                  })
                                : ''
                        }
                        disabled={signPageState === SignPageState.LOGIN}
                    >
                        {signPageState === SignPageState.LOGIN && (
                            <button
                                type="button"
                                className="absolute right-12 top-1/2 z-10 -translate-y-1/2 hover:cursor-pointer"
                                onClick={backToInitial}
                            >
                                <PencilSquareIcon className="w-20 cursor-pointer" />
                            </button>
                        )}
                    </Input>

                    {signPageState === SignPageState.LOGIN && (
                        <PasswordInput
                            required
                            id="password"
                            name="password"
                            autoComplete="current-password"
                            value={value.password}
                            label={formatMessage({
                                id: 'password',
                                defaultMessage: 'Password'
                            })}
                            placeholder={formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                            onChange={handleChange}
                            data-cy="password"
                            errorMessage={
                                loginFormErrors?.password
                                    ? formatValidationMessage({
                                          id: loginFormErrors.password,
                                          defaultMessage: 'Enter your password'
                                      })
                                    : ''
                            }
                        />
                    )}
                    {signPageState === SignPageState.FORGOT_PASSWORD && (
                        <div>
                            <ArrowLeftIcon className="w-4 cursor-pointer" onClick={goToLogin} />
                        </div>
                    )}
                    {signPageState === SignPageState.LOGIN && (
                        <div className="mb-15 mt-1 flex items-center justify-end">
                            <Link className="cursor-pointer text-md text-secondary-black underline" onClick={toResendPassword}>
                                {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                            </Link>
                        </div>
                    )}
                    <Button
                        size="full"
                        type="submit"
                        className="text-base font-bold leading-tight"
                        variant="primary"
                        disabled={loading || !reCaptchaReady}
                        loading={loading || !reCaptchaReady}
                    >
                        {pageData.buttonText}
                    </Button>

                    <ReCaptcha innerRef={reCaptchaRef} errors={error} setReadyState={setReCaptchaReady} />

                    {signPageState === SignPageState.LOGIN && accountData?.type === 'B2B' && (
                        <>
                            {loginInfoSettings.loginCompanyAdditionalInfoEnabled && (
                                <div className="m-auto mb-20 max-w-[530px] pb-20 ">
                                    <Markdown className="text-base" markdown={formatCompanyAdditionalInfoMessage} />
                                </div>
                            )}
                        </>
                    )}
                </div>
                {signPageState === SignPageState.FORGOT_PASSWORD && (
                    <Link
                        className="mx-auto my-10 block w-fit cursor-pointer text-20 font-bold hover:underline"
                        link=""
                        onClick={goToLogin}
                    >
                        {formatAccountMessage({ id: 'account.back.sign', defaultMessage: 'Back to sign in' })}
                    </Link>
                )}
            </form>

            <Modal
                isOpen={isRedirectModalOpen}
                className="relative w-[90%] rounded-md bg-white"
                style={{ content: { maxWidth: '545px' } }}
                closeTimeoutMS={200}
                preventScroll={true}
            >
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    <Markdown markdown={registrationRedirectTextContent} className="markdown" />
                    <Button
                        data-cy="close-membership-modal"
                        variant="secondary"
                        size="full"
                        onClick={closeRedirectModal}
                        className="rounded-md md:rounded-lg"
                    >
                        {registrationRedirectButtonText}
                    </Button>
                </div>
            </Modal>

            <ActivationModal
                isActivationModalOpen={isActivationModalOpen}
                setIsActivationModalOpen={setIsActivationModalOpen}
                email={data.email}
            />
        </>
    );
};

export default LoginForm;
